<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col class="col-xs-12 col-md-6 justify-center center" >
          <v-card
                  style="border-radius: 14px"
                  elevation="6"
          >
            <v-img
                 contain
                 src="../assets/images/website/product-intro.jpg"
                 alt="Squoll.com dynamise vos réunions, vos conférences et vos webinaires."
            />

          </v-card>
        </v-col>
        <v-col class="col-xs-12 col-md-6 text-center">
          <h1 class="display-2">
            Découvrez Squoll
          </h1>
          <p class="display-1">
            Libérez votre auditoire !<br> Posez-lui des questions, sonder ses opinions, obtenez des évaluations en temps réel en utilisant notre outil en ligne.
            <br><strong>Squoll est simple et facile</strong>.
          </p>
          <v-btn
            class="align-center secondary"
            to="/"
          >
            Soyez averti de l'ouverture
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <hr>
    <!-- Produits -->
    <div ref="polls" v-for="(item) in items" :key="item.title">
      <v-container
        class="mt-4"
      >
        <v-row align="center">
          <v-col v-if="((item.imgPos != 'right') && ($vuetify.breakpoint.name != 'xs'))" class="col-xs-12 col-sm-6">
            <img class="product-image"
                    :src="require('../assets/images/mockups/' + item.imgSrc)"
                    :alt="item.imgAlt"
            />
          </v-col>
          <v-col class="col-xs-12 col-sm-6">
            <h1>{{ item.title }}</h1>
            <h1 class="display-1">
              {{ item.subtitle }}
            </h1>
            <p class="" v-html="item.HTMLtext"></p>
            <router-link v-if="item.linkURL"  :to=item.linkURL>
                {{item.linkText}}
            </router-link>
          </v-col>
          <v-col v-if="((item.imgPos == 'right') || ($vuetify.breakpoint.name == 'xs'))" class="col-xs-12 col-sm-6">
            <img class="product-image"
              :src="require('../assets/images/mockups/' + item.imgSrc)"
              :alt="item.imgAlt"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
  import {metaTagsMixin} from '../mixins/metaTagsMixin';

  export default {
    mixins: [metaTagsMixin],
    data () {
      return {
        items: [
          {
            title: 'Questions - Réponses',
            subtitle: 'Vos sessions de questions-réponses au maximum de l’efficacité.',
            HTMLtext: '<strong>Votre auditoire choisit les questions</strong> pour lesquelles il veut obtenir les réponses.' +
              '<br>Levez le frein de la timidité : chaque participant peut choisir de poser une question de façon «anonyme». ' +
              '<br>De votre côté, <strong>vous gardez le contrôle de la publication</strong> des questions.',
            linkURL:'/',
            // linkText: 'En savoir plus sur les sondages',
            linkText: 'Soyez averti de l\'ouverture',
            imgSrc: '3-squoll-fil-questions-reponses-md.png',
            imgAlt: 'Le sondage en temps réel lors d\'un wébinaire est facile avec Squoll.com',
            imgPos: 'right',
          },
          {
            title: 'Sondages',
            subtitle: 'Adaptez votre présentation en temps réel',
            HTMLtext: ' Quoi de mieux que de poser une question à choix multiple pour engager vos auditeurs? ' +
              '<br> Vous <strong>créez votre sondage en quelques secondes' +
              '</strong> et obtenez les <strong>réponses consolidées en temps réel</strong>. ' +
              '<br>Plus besoin de guetter la fenêtre de tchat pour vous faire une idée approximative de la tendance.',
            linkURL:'/',
            // linkText: 'En savoir plus sur les sondages',
            linkText: 'Soyez averti de l\'ouverture',
            imgSrc: '4-a-squoll-participant-reponse-sondage-md.png',
            imgAlt: 'Le sondage en temps réel lors d\'un wébinaire est facile avec Squoll.com',
            imgPos: '',
          },
          {
            title: 'Auto-évaluations',
            subtitle: 'L’indispensable outil d’analyse',
            HTMLtext: 'Aligner son discours, sa proposition de valeurs, sur les nuances d’opinions de son auditoire' +
                      'est un facteur de succès d’un wébinaire réussi. Squoll propose une activité d’auto-évaluation intuitive.' +
                      '<br>Même si vous n’aviez pas prévu de l’utiliser, il est probable que vous la proposerez lors de votre prochain évènement!',
            linkURL: '/',
            linkText: 'Soyez averti de l\'ouverture',
            imgSrc: '4-b-squoll-participant-evaluation-md.png',
            imgAlt: 'Le sondage en temps réel lors d\'un wébinaire est facile avec Squoll.com',
            imgPos: 'right'
          },
          {
              title: 'Nuage de mots',
              subtitle: 'Ludique : le pouvoir des mot-clés',
              HTMLtext: 'Le nuage de mots recense les mots clés de votre public et fait ressortir le <strong>vocabulaire important</strong>' +
                  ' dans votre contexte.<br>Le nuage de mots permet autant de <strong>«briser la glace»</strong> en début d’intervention, ' +
                  'que d’alimenter, un peu après, les questions d’un sondage avec Squoll.',
              linkURL: '/',
              linkText: 'Soyez averti de l\'ouverture',
              imgSrc: '4-c-squoll-participant-nuage-de-mots-md.png',
              imgAlt: 'Le nuage de mots avec Squoll.com permet de "briser la glace" lors de vos interventions',
              imgPos: ''
          },
          {
              title: 'Questionnaires de satisfaction',
              subtitle: 'Plus simple, y’a pas !',
              HTMLtext: 'Démontrer que vous faîtes preuve d’écoute dès le début de votre relation. <br>Avec Squoll, vous montrez ' +
                  'à vos participants que leur avis compte et que <strong>vous êtes attentif à leur satisfaction</strong>. ' +
                  '<br>En interrogeant votre auditoire pendant votre intervention – y compris à la fin – vous maximisez ' +
                  'le nombre de réponses et <strong>améliorer la fiabilité de vos résultats</strong>.',
              linkURL: '/',
              linkText: 'Soyez averti de l\'ouverture',
              imgSrc: '4-d-squoll-participant-satisfaction-md.png',
              imgAlt: 'Lancer un questionnaire de satisfaction en temps réel c\'est facile avec Squoll.com',
              imgPos: 'right'
          },
          {
              title: 'Reciblage - Retargeting',
              subtitle: 'Recontacter facilement vos participants',
              HTMLtext: 'Squoll intègre les technologies <strong>Facebook Pixel</strong> et <strong>Google Ads</strong> : utilisez efficacement les techniques ' +
                  'de reciblage publicitaire - retargeting – pour rester proches des participants qui manifestent un intérêt ' +
                  'pour votre expertise.'+
                  '<br>Naturellement, <strong>Squoll est conforme RGPD</strong> : nous vous garantissons de ne cibler que les personnes' +
                  ' qui ont donné leur accord.',
              linkURL: '/',
              linkText: 'Soyez averti de l\'ouverture',
              imgSrc: '1-squoll-facebook-pixel-google-ads-mob-md.png',
              imgAlt: 'Squoll.com intégre les technologies de reciblage Facebook Pixel et Google Ads',
              imgPos: ''
          },
            {
                title: 'Analyses statistiques',
                subtitle: 'L’essentiel en un instant',
                HTMLtext: 'Obtenez des renseignements précieux sur votre auditoire ' +
                    'Découvrez les sujets qui interpellent votre public et utilisez-les pour <strong>vos prises de décisions</strong>.' +
                    '<br>Squoll vous fournit les <strong>renseignements importants</strong> à retenir : vous identifiez les questions ' +
                    'et les sujets qui ont trouvé écho auprès <strong>de votre public</strong>. <br>Et parce que vous souhaitez sans doute ' +
                    'aller au-delà du simple coup d’œil, toutes vos données et statistiques sont exportables au format PDF et Microsoft Excel.',
                linkURL: '/',
                linkText: 'Soyez averti de l\'ouverture',
                imgSrc: '3-squoll-rapport-synthese-webinaire-mob-md.png',
                imgAlt: '',
                imgPos: 'right'
            }

    ],
      }
    },
    created() {
      const metaInfo = {
        meta : [
          {name: 'description', content: 'Découvrez Squoll : sondages, évaluations, questions réponses en direct et ' +
              'd’autres fonctionnalités qui rendent vos webinaires et vos événements plus interactifs.'},
          {name: 'keywords', content: 'audience, auditoire, interaction, questions réponses, questions,  ' +
              'réponses, sondage,faire une sondage, présentations, conférences, événements, réunions, ' +
              'évaluations en direct, questionnaire de satisfaction en ligne, web app, technologie événementielle, ' +
              'sondages en direct, faire participer son public, faire un quiz en direct '}
        ]
      };
      this.appendOrReplaceMetaByOject(metaInfo, 'name');
    }
  }
</script>
<style>
  .product-image {
    display: block;
    max-height: 490px;
    margin-right: auto;
    margin-left: auto
  }
</style>
