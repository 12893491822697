<template>
  <v-app>
    <v-navigation-drawer
      v-if="false"
      app
    >
      <!-- -->
    </v-navigation-drawer>
    <app-header/>
    <!-- Sizes your content based upon application components -->
    <v-content>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view />
      </v-container>
    </v-content>
    <app-footer/> <!-- todo: remove after registration is ok -->
  </v-app>
</template>

<script>
  import Header from '../src/components/Header'
  import Footer from '../src/components/Footer'
  // import NavigationBar from './components/NavigationBar';

  export default {
    name: 'App',
    components: {
      AppHeader: Header,
      AppFooter: Footer,
      // AppNavigationBar: NavigationBar,
    },

    data() {
    //
      return {
      }
    },
    /*created() {
      console.log(process.env.VUE_APP_DEBUG_MODE);
      this.$store.dispatch('initDebugMode', process.env.VUE_APP_DEBUG_MODE);
    }*/
  }
</script>
