<template>
    <div>
        <div  class="text-center full-width ">
            <h1 class="display-2">Offre de lancement</h1>
            <p>lorem</p>
            <v-btn >Cliquez ici</v-btn>
            <v-btn class="inverted">Demandez une demo</v-btn>
        </div>
        <v-container >
            <v-row>
                <v-col  class="col-xs-12 text-center">
                    <h1>Nos offres sont adaptées</h1>
                    <p>pour augmenter la valeur de vos interventions, pas celle de votre budget.<br>
                        <span class="text--disabled">Tous nos prix sont hors taxes.</span></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="(priceType==0)" class="col-xs-12 col-md-8">
                    <h2>Tarifs mensuels</h2>
                    <p><strong>Sans engagement</strong>, vous décidez d'arrêter quand vous voulez.<br>
                        Idéal si votre activité est saisonnière.</p>
                </v-col>
                <v-col  v-else class="col-xs-12 col-md-8">
                    <h2>Tarifs annuels</h2>
                    <p><strong>La solution la plus économique</strong> pour utilisez Squoll sans limite.<br>
                        Payable en une fois.</p>
                </v-col>

                <v-col class="col-xs-12 col-md-4">
                    <v-switch
                            input-value=true
                            true-value =1
                            false-value=0
                            v-model="priceType"
                            :label="priceType==1?'annuel':'mensuel'"
                            @change="animatePrice"
                    ></v-switch>
                </v-col>
            </v-row>

            <!--les offres en grand-->
            <v-row >
                <v-col v-for="offer in offers" :key="offer.title" class="col-xs-12 col-sm-4 col-md-4">

                    <v-card class="pricing-card" hover style="cursor: default" >
                        <span v-if="offer.preferred" class="selected info white--text">{{offer.preferred}}</span>
                        <v-card-title class="justify-center" :class="offerClass(offer,priceType)">
                            {{offer.title}}
                        </v-card-title>
                        <v-card-text>

                    <transition name="flip" mode="out-in">
                            <div v-if="show"
                                class="card-price font-weight-bold display-2 "
                                :class="offerClass(offer,priceType)">
                                {{offer.price[priceType].euros}}€<span class="body-1">{{offer.price[priceType].cents}}</span>
                                <span class="body-1 d-block">{{offer.price[priceType].text}}</span>
                            </div>
                    </transition>
                            <div class="body-1">
                                Pour chaque évènement:
                                <ul class="no-bull text-center">
                                    <li v-for="(bullet, index) in offer.bullets" :key="index" v-html="bullet"></li>
                                </ul>
                            </div>
                        </v-card-text>
                        <v-card-actions class="justify-center" >
                            <v-btn rounded outlined :class="offerClass(offer,priceType)">{{offer.button.text}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-center text--disabled">
                    Vous avez d'autres besoin? <router-link to="/contact" class="text--disabled">contactez-nous</router-link>
                </v-col>
            </v-row>
            <!--Le tableau détaillé-->
            <v-row class="hidden-xs-only">
                <v-col>
                    <v-simple-table fixed-header>
                            <thead>
                            <tr>
                                <th></th> <!-- colonne entete vide -->
                                <th v-for="(offer, index) in offers" :key="index" class="text-center" :class="offerClass(offer,priceType)">{{offer.title}} </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, rowIndex) in offersDetails" :key="rowIndex" >
                                <td v-for="(detail, colIndex) in row" :key="colIndex" class="text-center" :style="tdStyle(rowIndex)">
                                    <span v-if="detail.hasOwnProperty('text')"  :class="detail.class">{{detail.text}}</span>
                                    <span v-if="(detail.hasOwnProperty('icon') && detail.icon)" > <v-icon :color="iconColor(colIndex)">mdi-check-circle</v-icon> </span>
                                </td>
                            </tr>
                            </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                priceType : "0",
                show: true,
                offers: [
                    {title: 'Gratuit',
                        preferred: false,
                        price: [{euros: 0, cents: '', text:'/mois'}, {euros: 0, cents: '', text:'/an'}],
                        bullets: ['<span class="font-weight-bold">8</span> activités',
                            '<span class="font-weight-bold">Questions</span> de l\'auditoire', '-',
                            '<span class="font-weight-bold">Rapport</span> de synthèse', '-', '-',
                            '<span class="font-weight-bold">Reciblage</span> - retargetting',
                            '<span class="font-weight-bold">50</span> participants maximum' ],
                        button:{text: 'Commencer', route:'/'}},
                    {title: 'Pop',
                        preferred: 'votre favori',
                        price: [{euros: 24, cents: 99, text:'/mois'}, {euros: 119, cents: 99, text:'/an'}],
                        bullets: ['<span class="font-weight-bold">20</span> activités',
                            '<span class="font-weight-bold">Questions</span> de l\'auditoire', '-',
                            '<span class="font-weight-bold">Rapport</span> de synthèse',
                            'Export <span class="font-weight-bold">PDF</span> et <span class="font-weight-bold">Excel</span>', '-',
                            '<span class="font-weight-bold">Reciblage</span> - retargetting',
                            '<span class="font-weight-bold">500</span> participants maximum' ],
                        button:{text: 'Acheter', route:'/'}},
                    {title: 'Top',
                        preferred: false,
                        price: [{euros: 34, cents: 99, text:'/mois'}, {euros: 179, cents: 99, text:'/an'}],
                        bullets: ['Nombre d\'activités <span class="font-weight-bold">illimité</span>',
                            '<span class="font-weight-bold">Questions</span> de l\'auditoire',
                            '<span class="font-weight-bold">Modération</span> des questions',
                            '<span class="font-weight-bold">Rapport</span> de synthèse',
                            'Export <span class="font-weight-bold">PDF</span> et <span class="font-weight-bold">Excel</span>',
                            '<span class="font-weight-bold">Ordre du jour</span> & informations',
                            '<span class="font-weight-bold">Reciblage</span> - retargetting',
                            '<span class="font-weight-bold">1 000</span> participants maximum' ],
                        button:{text: 'Acheter', route:'/'}},
                ],
                offersDetails: [
                        [{class: 'text-right font-weight-bold', text:'Engagement avec votre auditoire'}, {}, {}, {}],
                        [{class: '', text:'Questions de l\'auditoire'}, {icon:true}, {icon:true}, {icon:true}],
                        [{class: '', text:'Modérations des questions'}, {icon:false}, {icon:false}, {icon:true}],
                        [{class: '', text:'Sondages'}, {class: '', text:'2 par évènements'}, {class: '', text:'5 par évènements'}, {class: '', text:'Illimité'}],
                        [{class: '', text:'Evaluations'}, {class: '', text:'2 par évènements'}, {class: '', text:'5 par évènements'}, {class: '', text:'Illimité'}],
                        [{class: '', text:'Nuage de mots'}, {class: '', text:'2 par évènements'}, {class: '', text:'5 par évènements'}, {class: '', text:'Illimité'}],
                        [{class: '', text:'Questionnaire de satisfaction'}, {class: '', text:'2 par évènements'}, {class: '', text:'5 par évènements'}, {class: '', text:'Illimité'}],
                        [{class: '', text:'Nombre de maximum de participants'}, {class: '', text:'25'}, {class: '', text:'500'}, {class: '', text:'1000'}],
                        [{class: 'text-right font-weight-bold', text:'Rapport d\'analyses'}, {}, {}, {}],
                        [{class: '', text:'Rapport de synthèse d\'évènement'}, {icon:true}, {icon:true}, {icon:true}],
                        [{class: '', text:'Export des rapports - PDF et Excel'}, {icon:false}, {icon:true}, {icon:true}],
                        [{class: '', text:'Ordre du jour et informations'}, {icon:false}, {icon:false}, {icon:true}],
                        [{class: 'text-right font-weight-bold', text:'Marketing digital'}, {}, {}, {}],
                        [{class: '', text:'Reciblage - retargetting'}, {icon:true}, {icon:true}, {icon:true}],
                        [{class: 'text-right font-weight-bold', text:'Valorisation de votre marque'}, {}, {}, {}],
                        [{class: '', text:'10+  thèmes de présentation'}, {icon:false}, {icon:true}, {icon:true}],
                        [{class: '', text:'Personnalisation à vos couleurs'}, {icon:false}, {icon:false}, {icon:true}],
                        [{class: '', text:'Ajout de votre logo et de votre bannière'}, {icon:false}, {icon:false}, {icon:true}],
                        [{class: 'text-right font-weight-bold', text:'Sécurité'}, {}, {}, {}],
                        [{class: '', text:'Sécurisation de base de chaque compte'}, {icon:true}, {icon:true}, {icon:true}],
                        [{class: '', text:'Evénements privés'}, {icon:false}, {icon:true}, {icon:true}],

                        [{class: 'text-right font-weight-bold', text:'Support'}, {}, {}, {}],
                        [{class: '', text:'Support par email'}, {icon:true}, {icon:true}, {icon:true}],
                        [{class: '', text:'Assistance par tchat'}, {icon:false}, {icon:false}, {icon:true}],
                    /*
                        [{class: 'text-right font-weight-bold', text:'Intégration'}, {}, {}, {}],
                        [{class: '', text:'Google Slide'}, {}, {class: '', text:'à venir'}, {class: '', text:'à venir'}],
                        [{class: '', text:'intégration dans votre site web'}, {}, {class: '', text:'à venir'}, {class: '', text:'à venir'}],
                        */
                ]
            }
        },
        methods: {
            offerClass(offer, priceType) {
                let myClass = '';
                if(offer.price[priceType].euros == 0) {
                    myClass = 'outlined';
                } else {
                    if(offer.preferred) {
                        myClass = 'success white--text';
                    } else {
                        myClass = 'secondary white--text';
                    }
                }
                return myClass;
            },
            iconColor(colIndex) {
                let color = '';
                switch (colIndex) {
                    case(2): color= this.$vuetify.theme.themes.light.success; //todo: trouver une méthode non basée sur l'index
                        break;
                    case(3): color= this.$vuetify.theme.themes.light.secondary; //todo: trouver une méthode non basée sur l'index
                        break;
                }
                return color;
            },
            tdStyle(rowIndex) {
                let style= 'width: 25%;';

                if(this.offersDetails[rowIndex][0].class.length == 0){
                    style += ' border: none !important;'
                }
                return style;
            },
            animatePrice() {
                this.show = false;
                setTimeout(() => {
                    this.show = true;
                }, 250)
            }
        }
    }
</script>

<style scoped>
    .full-width {
        margin-top: -12px;  /* todo : trouver un moyen de calcul auto*/
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding: 20px;
        height: 390px;
        background-image: linear-gradient(20deg,#f05a28,#f6931e);
        color: white;
    }
    .full-width .v-btn {
            border-radius: 16px;
        margin: 8px;
            color: #f6931e;
            background-color: white !important;
        }
    .full-width .v-btn:hover {
        transform: scale(1.1);
        color: #f05a28;

    }

    .full-width .v-btn.inverted{
        color: white;
        border: 1px solid white !important;
        background-color: transparent !important;
    }
    .full-width .v-btn.inverted:hover{
        /*height: 2em !important;*/
        color: #f05a28;
        background-color: white !important;

    }
    .no-bull {
        list-style-type: none;

    }
    .pricing-card {
        overflow: hidden;
    }
    .card-price {
        text-align: center;
        width: 180px;
        height: 180px;
        margin: 14px auto;
        padding-top: 46px;
        border-radius: 50%;
        border: 2px solid;
    }

    .selected {
        z-index: 10;
        width: 180px;
        height: 32px;
        padding: 0 20px;
        font-size: 12px;
        line-height: 25px;
        text-align: center;
        /*color: #fff;*/
        font-weight: bold;
        box-shadow: 0px 2px 5px #888888;
        /*background: gold;*/
        border-top: 5px solid ;
        border-bottom: 5px solid;
    /*//background: #palegoldenrod;*/
        transform: rotate(35deg);
        position: absolute;
        right: -47px;
        top: 17px;
    }


    .flip-enter {

    }

    .flip-enter-active {
        animation: flip-in 0.3s ease-out forwards;

    }
    .flip-leave {

    }
    .flip-leave-active {
        animation: flip-out 0.3s ease-out forwards;
    }
    @keyframes flip-out {
        from {
            transform: rotateY(0deg);
        }
        to {
            transform: rotateY(90deg);
        }
    }
    @keyframes flip-in {
        from {
            transform: rotateY(90deg);
        }
        to {
            transform: rotateY(0deg);
        }

    }
</style>