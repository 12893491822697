import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import moment from 'moment'

// disable the (annoying) production mode warning ("You are running Vue in development mode.")
Vue.config.productionTip = false;
const moment = require('moment');
require('moment/locale/fr');
Vue.use(require('vue-moment'), {
  moment
});


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
