<template>
  <div app>
    <v-system-bar
      v-if="false"
    >
      <v-spacer />

      <v-icon>mdi-window-minimize</v-icon>

      <v-icon>mdi-window-maximize</v-icon>

      <v-icon>mdi-close</v-icon>
    </v-system-bar>
    <v-app-bar class="primary"
          >
      <v-img src="../assets/logos/squoll-260x80.png" max-width="130px" class="hidden-sm-and-down mr-4"/>
      <router-link to="/"><v-img src="../assets/logos/avatar-app-72x72.png"
             max-width="40px"
             class="hidden-md-and-up"/>
      </router-link>
      <v-btn
        to="/"
        class="ma-2 secondary hidden-sm-and-down"
      >
        <v-icon>mdi-home</v-icon>
        <span>Accueil</span>
      </v-btn>
      <v-spacer />
      <v-btn
        to="/product"
        class="ma-2 secondary"
      >
        <v-icon>mdi-map</v-icon>
        <span class="hidden-sm-and-down"> Tour du produit</span>
        <span class="hidden-md-and-up"> Tour</span>
      </v-btn>
      <span v-if="$store.getters.debug">
      <v-btn
              to="/prices"
              class="ma-2 secondary"
      >
        <v-icon >mdi-map</v-icon>
        <span> Tarifs</span>
      </v-btn>
        <!--
        <v-btn
                @click="changeTheme('theme1')"
                class="ma-2 secondary"
        >
            <v-icon>mdi-map</v-icon>
            <span> Theme1</span>
        </v-btn>
        <v-btn
                @click="changeTheme('theme2')"
                class="ma-2 secondary"
        >
            <v-icon>mdi-map</v-icon>
            <span> Theme2</span>
        </v-btn>
        -->
        </span>
      <!--
            <v-btn value="nearby" class="ma-2">
                <span>Nearby</span>
                <v-icon>mdi-map-marker</v-icon>
            </v-btn>
            -->
    </v-app-bar>
    <!--app-bar sm and down-->
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
      methods: {
        /*changeFonts(fontName) {
            console.log("changeFont");
            console.log(fontName);
            this.$vuetify.theme.themes.light = this.$store.getters[themeName].light;
            this.$font-size-root = '32px !default';

        }
        */
      }
  }
</script>
<style>
  .logo {
    display: inline;
    background: url("../assets/logos/squoll-260x80.png");
    background-size: 130px 40px;
    padding: 28px;
  }
</style>
