export default {
    light: {
        primary: '#424242',
        accent: '#f05a28', //issu du logo
        accent1: '#EEEEEE',
        secondary: '#f6931e', //issu du logo
        info: '#0C88A6',
        success: '#2DA636',
        warning: '#F2E341',
        error: '#F24E29',
    },
    /*
    dark: {
        primary: '#344955',
        secondary: '#F9AA33',
        accent: '#232F34',
        error: '#F22B1B',
        info: '#4A6572',
        success: '#0BBF59',
        warning: '#F2AC33',
    }
    */



    /*
Accent    orange clair : #fb8c00 -> orange darken-1
Secondary:  orange foncé : #e65100 -> orange darken-4
Footer: gris clair : #EEEEEE -> grey lighten-3
Primary:    Gris foncé : #424242 -> grey darken-3
     */
}