import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import { preset } from 'vue-cli-plugin-vuetify-preset-reply/preset'
import Squoll from '../SquollTheme'

Vue.use(Vuetify)

const options = {
    breakpoint: { scrollbarWidth: 12 },
    theme: {
        dark: false,
        themes: Squoll,
    },
}

export default new Vuetify({
    // preset,
    ...options,

})
