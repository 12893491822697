<template>
  <div class="home">
    <h1>H1 - {{ username }}</h1>
    <h2>H2 - {{ password }}</h2>
    <h3>H3</h3>
    <h4>H4</h4>
    <h5>H5</h5>
    <p> et un peu de text</p>
    <v-btn class="primary">
      Primary
    </v-btn>
    <v-btn class="secondary">
      Secondary
    </v-btn>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-text-field
            v-model="username"
            label="Username"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-text-field
            v-model="password"
            label="password"
            type="password"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

  export default {
    name: 'Home',
    components: {
    },
    data () {
      return {
        username: '',
        password: '',
      }
    },
  }
</script>
