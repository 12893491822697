import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import ComingSoon from '../views/ComingSoon.vue'
import Product from '../views/Product.vue'
import Prices from '../views/Prices'
import UserAgreement from '../views/UserAgreement'
import NotFound from '../views/NotFound'

Vue.use(VueRouter)

  const routes = [
      {
        path: '/',
        name: 'ComingSoon',
        component: ComingSoon,
      },
      {
          path: '/product',
          name: 'Product',
          component: Product,
      },
      {
          path: '/prices',
          name: 'prices',
          component: Prices,
      },
      {
          path: '/home',
          name: 'Home',
          component: Home,
      },
      {
        path: '/user-agreement',
        name: 'UserAgreement',
        component: UserAgreement,
      },
      {
          path: '*',
          name: 'NotFound',
          component: NotFound,
      },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router
