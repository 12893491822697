import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        debug: process.env.VUE_APP_DEBUG_MODE,
        theme1: {
            light: {
                primary: '#143642',
                secondary: '#f6921e',
                accent: '#343434',
                error: '#a8201a',
                warning: '#f6921e',
                info: '#3c7a89',
                success: '#1a936f'
            }
        },
        theme2: {
            light: {
                primary: '#4caf50',
                secondary: '#ffc107',
                accent: '#cddc39',
                error: '#f44336',
                warning: '#9c27b0',
                info: '#3f51b5',
                success: '#009688'
            }
        }
    },
    getters: {
        debug: state => {
            return state.debug
        },
        theme1: state => {
            return state.theme1
        },
        theme2: state => {
            return state.theme2
        },
    },
    mutations: {
      /*
      'SET_DEBUG_MODE'(state, debugMode) {
        state.debug = debugMode;
      },
      */

   },

  actions: {
      /*
    initDebugMode: ({commit}, debugMode) => {
      commit('SET_DEBUG_MODE', debugMode);
    },
    */
  },
  modules: {
  },
})
