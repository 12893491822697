<template>
  <div app>
    <app-video-player :startVideo="startVideo"></app-video-player>
    <!--sm and up-->
    <v-container
      fluid
      class="hidden-sm-and-down home-hero-sm fill-height"
    >
      <v-row
        justify="center"
        align="center"
      >
        <v-col class="col-xs-12 pa-2">
          <h1 class="display-3 white--text text-center">Squoll.com</h1>
          <h2 class="mb-3 display-2 white--text text-center">Reveillez votre audience</h2>
          <div class="display-1 font-weight-bold white--text text-center">
            Transformez vos auditeurs en participants actifs pour gagner leur engagement.
          </div>
          <div class="display-1 font-weight-bold white--text text-center">
            <a  class="link-text" id="show-modal" @click="startVideo = !startVideo">Découvrez Squoll en video</a> ou
            <router-link
                    to="/product"
                    class="link-text"
            >
              explorez les fonctionnalités</router-link>
          </div>
          <app-send-in-blue/>
        </v-col>
      </v-row>

    </v-container>
    <!-- xs -->
    <v-container
      fluid
      class="hidden-md-and-up home-hero-xs fill-height"
    >
      <v-row
        class="align-top"
        justify="center"
      >
        <v-col>
          <h1 class="white--text text-center">Squoll.com</h1>
          <h2 class="white--text text-center">Reveillez votre audience</h2>
          <div class="font-weight-bold white--text text-center">
            Transformez vos auditeurs en participants actifs pour gagner leur engagement.
          </div>
          <div class="font-weight-bold white--text text-center">
            <!--<app-video-player :startVideo="startVideo"></app-video-player>-->
            <a  class="link-text" id="show-modal" @click="startVideo = !startVideo">Découvrez Squoll en video</a><br/>ou
            <router-link
                    to="/product"
                    class="link-text"
            >
              explorez les fonctionnalités</router-link>
          </div>
          <app-send-in-blue/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import {metaTagsMixin} from '../mixins/metaTagsMixin';
  import SendInBlue from '../../src/components/SendInBlue'
  import VideoPlayer from '../../src/components/VideoPlayer'

  export default {
    mixins: [metaTagsMixin],
    components: {
      AppSendInBlue: SendInBlue,
      AppVideoPlayer: VideoPlayer
    },
    data () {
      return {
        startVideo: false,
      }
    },

    created() {
      const metaInfo = {
          meta : [
            {name: 'description', content: 'Squoll est la plateforme de questions réponses et de sondages qui dynamise vos webinaires ' +
                'et vos évènements. Transformez votre auditoire en participants actifs et tirez profit du reciblage marketing.'},
            {name: 'keywords', content: 'audience, auditoire, interaction, questions réponses, questions,  ' +
                'réponses, sondage,faire une sondage, présentations, conférences, événements, réunions, ' +
                'évaluations en direct, questionnaire de satisfaction en ligne, web app, technologie événementielle, ' +
                'sondages en direct, faire participer son public, faire un quiz en direct '}
          ]
      };
      this.appendOrReplaceMetaByOject(metaInfo, 'name');
    }
  }
</script>
<style scoped>
  .link-text {
    color: white;
    text-decoration: underline;
  }
  .link-text:hover {
    text-decoration: none;
  }
</style>
<style scoped>
    .home-hero-sm {
        width: 100%;
        min-height: 1000px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        /*background-image: url("../assets/images/audience-passive-voices.01.jpg");*/
        background-image: url("../assets/images/audience-raising-hands-sm.jpg");
        background-size: cover;
        background-color: darkgrey;
        }
    .home-hero-xs {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        /*background-image: url("../assets/images/audience-passive-voices.01.jpg");*/
        background-image: url("../assets/images/audience-raising-hands-xs.jpg");
        background-size: cover;
        background-color: darkgray;
    }
</style>
