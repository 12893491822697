<template>
    <span>
         <!--<a  class="link-text" id="show-modal" @click="openModal">Découvrez Squoll en video</a>-->
        <!-- use the modal component, pass in the prop -->
            <transition name="modal">
                <div class="modal-mask" v-if="showModal" v-on:click="closeModal">
                    <div class="modal-wrapper" >
                        <div class="modal-container" :style="widthAndHeight" v-on:click.stop>
                            <iframe
                                :style="widthAndHeight"
                                :src="videoSrc"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                style="display: block;
                                border-radius: 10px 10px 10px;
                              margin-left: auto;
                              margin-right: auto;
                              max-width: 100%;"

                            >
                            </iframe>
                            <div class="modal-closer">
                                <v-icon class="modal-default-button" @click="closeModal">
                                    mdi-close
                                </v-icon>

                            </div>
                        </div>
                    </div>
                </div>
            </transition>
    </span>
</template>

<script>
    export default {
      props: ['startVideo'],
      watch: {
        startVideo: function () { // watch it
        // startVideo: function (newVal, oldVal) { // watch it
          // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
          if(this.showModal == false) {
            this.showModal = true;
          }
        }
      },
      data() {
        return {
          // dialog: false,
          showModal: false,
          src: 'https://www.youtube.com/embed/',
          defaultWidth: 560,
          defaultHeight: 315,
          videoId: 'rJjl0-tUhjM',
          showInfo: 0,
          showControls: 1,
          hideBranding: 1,
          showRelatedVideos: 0,
          autoPlay: 1
        }
      },
      computed: {
        widthAndHeight() {
          const ratio = this.defaultWidth / this.defaultHeight;
          const maxWidth = window.innerWidth;
          const maxHeight = window.innerHeight;
          //Calcul de la largeur théorique
          var width = Math.round(80 * maxWidth / 100);
          var height = Math.round(width / ratio);
          //Vérification de la hauteur
          if(height > maxHeight) {
            height = maxHeight;
            width  = Math.round(height * ratio);
          }
          return 'width: ' + width + 'px; height: ' + height + 'px;'
        },
        videoSrc() {
          var src = this.src + this.videoId +'?showinfo=' + this.showInfo + '&controls=' + this.showControls
            + '&modestbranding=' + this.hideBranding + '&rel=' + this.showRelatedVideos + '&iv_load_policy=3&autohide=1&autoplay=' + this.autoPlay;
          return src
        }
      },
      methods: {
        openModal() {
          this.showModal = true;
        },
        closeModal() {
          if(this.showModal == true) {
            this.showModal = false;

          }
        },

      },
      beforeMount() {

      },
      mounted() {

      },
      created () {

      }
    }
</script>

<style>
    .modal-mask {
        position: absolute;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        /*width: 500px;*/
        /*height: 500px;*/
        margin: 0px auto;
        /*padding: 20px 30px;*/
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
    }

    .modal-header {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-footer {
        padding: auto auto 0 0;
        margin: 0;
    }
    .modal-default-button {
        float: right;
    }
    .modal.closer {
        float: top;
    }
    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
</style>