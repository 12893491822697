export const metaTagsMixin = {
  methods: {
    appendMetaByOject(metaInfo) {
      /** meta tags injection **/
      var firstMetaTag = document.getElementsByTagName('meta')[0];
      if(Object.prototype.hasOwnProperty.call(metaInfo, 'meta')) {
        let meta = metaInfo.meta;
        meta.forEach(function (element) {
          var meta = document.createElement('meta');
          for (let [key, value] of Object.entries(element)) {
            meta.setAttribute(key, value);
          }
          firstMetaTag.parentNode.insertBefore(meta, firstMetaTag);
        })
      }
    },
    appendOrReplaceMetaByOject(metaInfo, propertyKey) {
      /** meta tags replace name **/
      var metaTags = document.getElementsByTagName('meta');
      if(Object.prototype.hasOwnProperty.call(metaInfo, 'meta')) {
        let meta = metaInfo.meta;
        meta.forEach(function (element) {
          //Préparation de l'élement
          var meta = document.createElement('meta');
          for (let [key, value] of Object.entries(element)) {
            meta.setAttribute(key, value);
          }
          //Exite-t-elle dans les metaTags?
          var found = false;
          metaTags.forEach(function (tag, key) {
            if(tag[propertyKey] === element[propertyKey]) {
              found = true;
              metaTags[key].parentNode.replaceChild(meta, tag);
            }
          });
          if(!found) {
            //Si on l'a pas trouvé on le crée
            var firstMetaTag = metaTags[0];
            firstMetaTag.parentNode.insertBefore(meta, firstMetaTag);
          }
        })
      }
    }
  },
}
